import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCoursesQualificationsList() {
	// Use toast
	const toast = useToast()

	const refUserListTable = ref(null)

	// Table Handlers
	let tableColumns = [
		{ key: 'access.fullName', sortable: false, label: 'Usuario' },        
		{ key: 'user_cq.course_cq', sortable: false, label: 'Curso'},	
        { key: 'user_cq.grade', sortable: false, label: 'Calificación' },
        { key: 'user_cq.state', sortable: false, label: 'Estado' },		
	]
	
	if (JSON.parse(localStorage.getItem('userData')).role == 'admin'){
		tableColumns.push({  key: 'customer.name', sortable: false, label: 'Cliente' });		
    }
	
	const perPage = ref(10)
	const totalUsers = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(false)
	const filterCustomer = ref(null)
	const filterStatus = ref(null)
	
	const dataMeta = computed(() => {
		const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalUsers.value,
		}
	})

	const refetchData = () => {
		refUserListTable.value.refresh()
	}

	watch([currentPage, perPage, searchQuery, filterStatus, filterCustomer], () => {
		refetchData()
	})

	
	const fetchCoursesQualifications = (ctx, callback) => {		
        store
            .dispatch('app-user/fetchCoursesQualifications', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,				
				customerFilter: filterCustomer.value ? filterCustomer.value.value : null,
				statusFilter: filterStatus.value  ? filterStatus.value.value : null,
				role: JSON.parse(localStorage.getItem('userData')).role,
            	customer: JSON.parse(localStorage.getItem('userData')).customer,
            })			
            .then(response => {
                const { users, total } = response.data
				callback(users)
				totalUsers.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de notas.',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            })
    };
	
	return {
		fetchCoursesQualifications,
		tableColumns,
		perPage,
		currentPage,
		totalUsers,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refUserListTable,

		refetchData,
		
		// Extra Filters
		filterStatus,
		filterCustomer,
	}
}
